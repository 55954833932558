<template>
  <div ref="map" id="map" style="height: 100%; width: 100%">
    <h1 class="d-flex align-items-center text-white fw-bolder p-10">
      Loading ....
    </h1>
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import io from "socket.io-client";

import "leaflet/dist/leaflet.css";
import L from "leaflet";
export default defineComponent({
  name: "Atributisasi",
  data() {
    return {
      map: null,
      markers: [],
    };
  },
  mounted() {
    const socket = io(ApiService.vueInstance.axios.defaults.baseURL);
    socket.emit("markerAttributions");
    this.map = L.map("map", {
      zoomControl: true,
      zoom: 1,
      zoomAnimation: false,
      fadeAnimation: true,
      markerZoomAnimation: true,
    }).setView([-0.717206, 102.718722], 6);

    // Add tile layer (you can choose a different one)
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      this.map
    );

    // // Fetch initial markers from your server
    socket.on("markers", (data) => {
      this.addMarkers(data);
    });
  },
  methods: {
    addMarkers(markers) {
      markers.forEach((marker) => {
        this.addMarker(marker);
      });
    },
    addMarker(marker) {
      const { id, documentation, latitude, longitude } = marker;

      // Create a custom icon for the marker
      const path = window.location.origin + "/";
      const icon = path + "other/png/green.png";
      const customIcon = L.icon({
        iconUrl: icon, // Replace with the path to your custom icon image
        iconSize: [24, 24], // Size of the icon
        iconAnchor: [16, 32], // Anchor point of the icon
      });

      // popup content
      const popupContent = `<img class="img-fluid" src="${documentation}" alt="foto kegiatan 1" style="max-width:150px"><a class="btn btn-primary btn-sm btn-block mt-2" href="/attribute-map/detail/${id.toString()}" target="_blank">Lihat selengkapnya</a>`;

      const customMarker = L.marker(new L.LatLng(latitude, longitude), {
        icon: customIcon,
      })
        .bindPopup(popupContent)
        .addTo(this.map);
      this.markers.push(customMarker);
    },
  },
  setup() {
    setCurrentPageTitle("Atributisasi");
  },
});
</script>
